<template>
   <b-modal
      id="modal-add-transfer-form"
      title="Thêm mới phiếu chuyển cửa hàng"
      hide-footer
    >
      <v-form lazy-validation>
        <!-- For manager or president -->
        <div v-if="checkJobTitleById">
          <!-- Employee's name employee's code -->
          <div class="w-100 mb-5">
            <label>Họ tên, mã NV:</label>
            <Autosuggest
              :model="searchEmployee"
              :suggestions="filteredOptionsEmployees"
              @change="onInputChangeEmployee"
              @select="onSelectedEmployee"
              aria-describedby="input-employee-name-code"
              size="sm"
              suggestionName="suggestionName"
              placeholder="tên hoặc mã nhân viên"
            />
          </div>
          <!-- Current store -->
          <div class="w-100 mb-5">
            <label>Cửa hàng hiện tại:</label>
            <b-input
              v-model="currentStoreName"
              type="text"
              size="sm"
              single-line
              hide-details
              disabled
            />
          </div>
        </div>

        <!-- For employees -->
        <div v-else>
          <div class="w-100 mb-5">
            <label>Họ tên, mã NV:</label>
            <b-input
              v-model="currentEmployeeFullName"
              type="text"
              size="sm"
              single-line
              hide-details
              disabled
            />
          </div>
          <div class="w-100 mb-5">
            <label>Cửa hàng hiện tại:</label>
            <b-input
              v-model="currentEmployeeStoreName"
              type="text"
              size="sm"
              single-line
              hide-details
              disabled
            />
          </div>
        </div>
    
        <!-- Transfer store -->
        <div class="w-100 mb-5">
          <label>Cửa hàng muốn chuyển đến:</label>
          <Autosuggest
            :model="searchTargetStore"
            :suggestions="filteredOptions"
            @select="onSelectedTargetStore"
            @change="onInputChangeTargetStore"
            placeholder="tên cửa hàng muốn chuyển đến"
            suggestionName="suggestionName"
          />
        </div>
        <!-- NOTE -->
        <div class="w-100 mb-5">
          <label>Ghi chú:</label>
          <b-form-textarea
            v-model="description"
            id="textarea"
            size="sm"
            placeholder="Lý do chuyển cửa hàng"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>

        <b-button
          size="sm"
          variant="primary"
          class="mr-3"
          style="width: 100px"
          @click="createTransferForm"
        >Tạo phiếu
        </b-button>

        <b-button
          size="sm"
          variant="secondary"
          class="mr-3"
          style="width: 100px"
          @click="hideModalAddForm"
        >Hủy
        </b-button>
      </v-form>
    </b-modal>
</template>

<script>
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import ApiService from '@/core/services/api.service';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { removeAccents } from '@/utils/common';
import { JOB_TITLE } from '@/utils/enum';

export default {
  components: { Autosuggest },
  props: {
    userInfo: { 
      type: Object,
    },
    listStores: {
      type: Array,
    },
  },  
  data() {
    return {
      filteredOptionsEmployees: [],
      optionsForSearch: [],
      filteredOptions: [],
      optionsEmployees: [],
      options: [],
      listStoreId: [],
      searchEmployee: '',
      selectedEmployeeId: null,
      currentStoreName: null,
      currentStoreId: null,
      searchTargetStore: null,
      description: '',
      // For employee
      currentEmployeeFullName: null,
      currentEmployeeId: null,
      currentEmployeeStoreName: null,
      currentEmployeeStoreId: null,
    }
  },
  watch: {
    listStores(newValue) {
      this.options = newValue;
      if(!this.checkJobTitleById) {
        this.options = this.filterOptionsListStore(this.userInfo.listStoreId)
      }
      this.filteredOptions = [...this.options];
      this.optionsForSearch = [...this.options];
    }
  },
   mounted (){
     const { fullName, employeeId } = this.userInfo;
     if (fullName) this.currentEmployeeFullName = fullName;
     if (employeeId) this.currentEmployeeId = employeeId;
     this.fetchCurrentStoreByEmployeeId();
     this.fetchStoreAndEmployee()
  },
  computed: {
    checkJobTitleById(){
      const jobTitleIds = [
        JOB_TITLE.GD,
        JOB_TITLE.QL,
        JOB_TITLE.TL,
        JOB_TITLE.ASM,
        JOB_TITLE.RSM,
      ];
      const { jobTitleId } = this.userInfo;
      return jobTitleIds.includes(jobTitleId)
    },
  },
  methods: {
    createTransferForm: async function () {
      const data = {
        data: {
          employeeId: this.checkJobTitleById ? this.selectedEmployeeId : this.currentEmployeeId,
          currentStoreId: this.checkJobTitleById ? this.currentStoreId : this.currentEmployeeStoreId,
          targetStoreId: this.targetStoreId,
          description: this.description,
        },
      };
      const apiAction = this.id ? ApiService.put : ApiService.post;

      try {
        const res = await apiAction('transfer-store-forms', data);
        if (res.data.status === 1) {
          makeToastSuccess(res.data.message);
          this.hideModalAddForm();
          this.$emit('fetchTransferStoreForms')
        } else {
          makeToastFaile(data.message);
        }
      } catch (error) {
        makeToastFaile(
          error.response.data ? error.response.data.message : 'Lỗi'
        );
      }
    },
    fetchCurrentStoreByEmployeeId :  function () {
      ApiService.query('employees' + '/' + this.currentEmployeeId).then((response) => {
      const dataRes = response.data.data;
      this.currentEmployeeStoreName = dataRes.storeName;
      this.currentEmployeeStoreId = dataRes.storeId;
    })
    },
    fetchEmployeeByStores: async function () {
      ApiService.get(
        `employees/get-by-store?employeeName=&storeId=${this.listStoreId}`
      ).then((response) => {
        const employeesList = response.data.data || [];
        this.isSearching = false;
        const employeesListResult = employeesList.map((employee) => ({
          ...employee,
          suggestionName: this.getSelectedEmployeeInfo(employee),
        }));
        this.optionsEmployees = [...employeesListResult];
        this.filteredOptionsEmployees = [...employeesListResult];
      });
    },
    fetchStoreAndEmployee() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.listStoreId = data.data.stores.map((element)=>{
            return element.id
          });
          this.fetchEmployeeByStores();
         
        }
      })
    },
    onInputChangeEmployee(text) {
      this.searchEmployee = text;
      const filterData = this.optionsEmployees
        .filter((item) => {
          return (
            removeAccents(item.suggestionName)
              .toLowerCase()
              .indexOf(removeAccents(text).toLowerCase()) > -1
          );
        })
      this.filteredOptionsEmployees = [...filterData];
      if (this.searchEmployee == '') {
        this.filteredOptionsEmployees = [...this.optionsEmployees];
        this.selectedEmployeeId = null
      }
      if (!this.searchEmployee) {
        this.selectedEmployeeId = null
      }
    },
    onInputChangeTargetStore(text) {
      if (!text) {
        text = '';
      }
      this.searchTargetStore = text;
      const filteredData = this.filteredOptions
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
      this.filteredOptions = [...filteredData];
      if( this.searchTargetStore == ''){
        this.filteredOptions = this.optionsForSearch;
        this.targetStoreId = null
      }
      if (!this.searchTargetStore){
        this.targetStoreId = null
      }
    },
    onSelectedEmployee(option) {
      this.searchEmployee = this.getSelectedEmployeeInfo(option.item);
      this.selectedEmployeeId = option.item.id;
      this.currentStoreId = option.item.storeId;
      this.currentStoreName = this.getStoreName(this.currentStoreId) || 'Chưa có';
      const listStore = this.filterOptionsListStore(option.item.listStoreId)
      this.optionsForSearch = listStore
      this.filteredOptions = listStore;
    },
    filterOptionsListStore(listStoreIdString) {
      const listStoreOfUser = listStoreIdString.split(',').map((element) => {
        return Number(element)
      })
      const listStore = this.options.filter((item) => {
        return !listStoreOfUser.includes(item.id)
      })
      return listStore
    },
    getStoreName(storeId){
      const store = this.options.find(item => item.id === storeId );
      return store ? store.name : null;
    },
    onSelectedTargetStore(option) {
      this.targetStoreId = option.item.id;
      this.searchTargetStore = option.item.name;
    },
    getSelectedEmployeeInfo(info) {
      const fullName = info.fullName;
      const code = info.code;
      const jobTitleName = info.jobTitleName;
      return [fullName, code, jobTitleName].filter(Boolean).join(' - ').trim();
    },
    hideModalAddForm(){
      this.$bvModal.hide('modal-add-transfer-form');
      this.searchEmployee = '';
      this.selectedEmployeeId = null;
      this.currentStoreId = '';
      this.currentStoreName = '';
      this.targetStoreId = null;
      this.searchTargetStore = null;
    },
  },
}
</script>

<style>

</style>