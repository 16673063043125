<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'Danh sách phiếu chuyển cửa hàng'">
      <template v-slot:toolbar>
        <b-button v-b-modal:[`modal-add-transfer-form`] variant="primary" size="sm">
          <i style="font-size: 0.7rem" class="flaticon2-add-1" />
          <span class="font-weight-bolder">Thêm mới</span>
        </b-button>
      </template>
      <template v-slot:preview>
        <div class="col-md-12 mb-5">
          <b-row>
            <b-col md="4">
              <b-input
                size="sm"
                placeholder="Nhập tên hoặc mã nhân viên"
                v-model="search"
                append-icon="search"
                single-line
                hide-details
                v-on:keyup.enter="onFilter()"
              />
            </b-col>
            <b-col md="4">
              <Autosuggest
                :model="searchCurrentStore"
                :suggestions="filteredOptions"
                placeholder="Tìm kiếm cửa hàng hiện tại"
                @select="onSelectedCurrentStore"
                :limit="limitPerPage"
                @change="onInputChangeCurrentStore"
                suggestionName="suggestionName"
              >
              </Autosuggest>
            </b-col>
            <b-col md="4">
              <Autosuggest
                :model="searchTargetStore"
                :suggestions="filteredOptions"
                @select="onSelectedTargetStore"
                :limit="limitPerPage"
                placeholder="Nhập tên cửa hàng muốn chuyển đến"
                @change="onInputChangeTargetStore"
                suggestionName="suggestionName"
              ></Autosuggest>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col md="4">
              <b-form-group>
                <date-picker
                  placeholder="Ngày tạo từ"
                  class="form-control form-control-sm date-picker"
                  :config="dpConfigs.date"
                  v-model="createdAtStart"
                ></date-picker>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <date-picker
                  placeholder="Ngày tạo đến"
                  class="form-control form-control-sm date-picker"
                  :config="dpConfigs.date"
                  v-model="createdAtEnd"
                ></date-picker>
              </b-form-group>
            </b-col>
            <div
              class="d-flex ml-4"
              style="max-height: 32px; align-items: flex-end"
            >
            <b-button-group size="sm">
                    <b-button
                      style="width: 70px"
                      class="font-weight-bolder"
                      variant="primary"
                      @click="onFilter"
                      ><i class="fas fa-search text-white fa-lg"></i
                      >Lọc</b-button
                    >
            </b-button-group>
              <b-button
                variant="success"
                size="sm"
                class="mt-3 ml-3"
                style="min-width: 2rem; height: 100%; margin-top: 0"
                @click="reset"
                >Làm mới</b-button
              >
            </div>
          </b-row>
          <div v-show="allowUpdateManyItems" v-if="checkAllowUpdateManyItems">
              <b-button
                variant="primary"
                size="sm"
                class="mt-3"
                style="min-width: 5rem"
                v-b-modal.md-change-store
              >
                Duyệt và chuyển</b-button
              >
              <b-button
                variant="success"
                size="sm"
                class="mt-3 ml-3"
                style="min-width: 5rem"
                v-b-modal.modal-approve-many-items
              >
                Duyệt</b-button
              >
              <b-button
                variant="warning"
                size="sm"
                class="mt-3 ml-3"
                style="min-width: 2rem"
                v-b-modal.modal-reject-many-items
                >Từ chối</b-button
              >
              <b-button
                variant="danger"
                size="sm"
                class="mt-3 ml-3"
                style="min-width: 2rem"
                v-b-modal.modal-delete-many-items
                >Xoá đơn</b-button
              >
          </div>
        </div>
        <div class="mb-4 tab-status">
          <b-nav tabs Justified >
            <b-nav-item  v-for="(status,index) in statusOptions" :key="index" @click="handleTabsStatus(status.value,index)"  :active="index == tabIndex ?true:false">{{status.text}}</b-nav-item>
          </b-nav>
        </div>

        <b-table
          :items="listForms"
          :fields="computedFields"
          sort-by="count"
          bordered
          hover
          :busy="onLoading"
          class="mt-7"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:head(selections)>
            <span class="d-flex justify-content-center">
              <b-form-checkbox
                v-model="checkAllItems"
                size="lg"
                @change="onCheckAllItems"
              ></b-form-checkbox>
            </span>
          </template>
          <template v-slot:cell(selections)="row">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                :disabled="checkPermissionByEmployId(row.item) ? false :true"
                :checked="selections.some((id) => id === row.item.id)"
                @change="onSelectFormItem(row.item.id)"
              ></b-form-checkbox>
            </div>
          </template>
          <template v-slot:cell(count)="row">
            <div style="text-align: center">
              <span v-text="row.item.count" class="text-right"></span>
            </div>
          </template>

          <template v-slot:cell(code)="row">
            <a v-bind:href="getLinkDoc(row.item)">
              <span>
                {{ row.item.code }}
              </span>
            </a>
          </template>
          <template v-slot:cell(status)="row">
            <StatusForm
              :status="row.item?.status"
              :timestamp="row.item?.approvedAt"
              :performer="row.item?.approvedByName"
            />
          </template>

          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item
                  @click="editItem(row.item)"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 10px" class="flaticon2-box-1"></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>
                <template v-if="checkPermission('EDIT_TRANSFER_STORE_FORM') ">
                  <template v-if="row.item.status === statusEnum.PENDING && checkPermissionByEmployId(row.item)">
                    <b-dropdown-item v-b-modal.md-change-store @click="onOpenModalApproveTransfer(row.item)">
                      <span class="text-primary" style="font-size: 12px">
                        <i style="font-size: 10px" class="flaticon2-check-mark text-primary"></i>
                        &nbsp; Duyệt và chuyển
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item v-b-modal.modal-approve @click="onOpenModalApprove(row.item)">
                      <span class="text-success" style="font-size: 12px">
                        <i style="font-size: 10px" class="flaticon2-check-mark text-success"></i>
                        &nbsp; Duyệt
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item v-b-modal.modal-reject @click="onOpenModalReject(row.item)">
                      <span class="text-warning" style="font-size: 12px">
                        <i style="font-size: 10px" class="flaticon2-exclamation text-warning"></i>
                        &nbsp; Từ chối
                      </span>
                    </b-dropdown-item>
                  </template>
                  <b-dropdown-item v-b-modal.modal-delete @click="onOpenModalDelete(row.item)">
                    <span class="text-danger" style="font-size: 12px">
                      <i style="font-size: 10px" class="flaticon2-cancel text-danger"></i>
                      &nbsp; Xoá đơn
                    </span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </div>
          </template>
        </b-table>

        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ formatMoney(totalItems) }}
            </p></b-col
          >
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-show="numberOfPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPages"
              use-router
              @change="fetchTransferStoreForms"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
    <TransferStoreInformation
    :listForm="currentManyFormsOpenModal"
    @fetchTransferStoreForms="fetchTransferStoreForms"
    />
   
    <AddTransferStoreFormModal 
      @fetchTransferStoreForms="fetchTransferStoreForms"
      :userInfo="userInfo"
      :listStores="options"
    />

    <b-modal
      ref="modal-approve"
      id="modal-approve"
      title="Bạn có đồng ý duyệt đơn không?"
      hide-footer
    >
      <div>
        <p>
          <span style="font-weight: 500">Họ tên nhân viên:</span>
          {{ currentFormOpenModal?.employeeName }}
          {{
            currentFormOpenModal?.employeeCode
              ? `(${currentFormOpenModal?.employeeCode})`
              : ''
          }}
        </p>
        <p>
          <span style="font-weight: 500">Cửa hàng hiện tại:</span>
          {{ currentFormOpenModal?.currentStoreName }}
        </p>
        <p>
          <span style="font-weight: 500">Cửa hàng yêu cầu chuyển đến:</span>
          {{ currentFormOpenModal?.targetStoreName }}
        </p>
      </div>
      <div class="d-flex justify-content-start">
        <b-button @click="hideModalApprove" variant="primary" size="sm"
          >Quay lại</b-button
        >
        <b-button
          @click="onHandleApprove"
          variant="success"
          class="ml-3"
          size="sm"
          >Tiến hành duyệt</b-button
        >
      </div>
    </b-modal>
    <b-modal
      ref="modal-approve-transfer"
      id="modal-approve-transfer"
      title="Bạn có đồng ý duyệt đơn không?"
      hide-footer
    >
      <div>
        <p>
          <span style="font-weight: 500">Họ tên nhân viên:</span>
          {{ currentFormOpenModal?.employeeName }}
          {{
            currentFormOpenModal?.employeeCode
              ? `(${currentFormOpenModal?.employeeCode})`
              : ''
          }}
        </p>
        <p>
          <span style="font-weight: 500">Cửa hàng hiện tại:</span>
          {{ currentFormOpenModal?.currentStoreName }}
        </p>
        <p>
          <span style="font-weight: 500">Cửa hàng yêu cầu chuyển đến:</span>
          {{ currentFormOpenModal?.targetStoreName }}
        </p>
      </div>
      <div class="d-flex justify-content-start">
        <b-button @click="hideModalApproveTransfer" variant="primary" size="sm"
          >Quay lại</b-button
        >
        <b-button
          @click="onHandleApproveTransfer"
          variant="success"
          class="ml-3"
          size="sm"
          >Tiến hành duyệt và chuyển</b-button
        >
      </div>
    </b-modal>
    <b-modal
      ref="modal-reject"
      id="modal-reject"
      title="Bạn có đồng ý từ chối yêu cầu không?"
      hide-footer
    >
      <div>
        <p>
          <span style="font-weight: 500">Họ tên nhân viên:</span>
          {{ currentFormOpenModal?.employeeName }}
          {{
            currentFormOpenModal?.employeeCode
              ? `(${currentFormOpenModal?.employeeCode})`
              : ''
          }}
        </p>
        <p>
          <span style="font-weight: 500">Cửa hàng hiện tại:</span>
          {{ currentFormOpenModal?.currentStoreName }}
        </p>
        <p>
          <span style="font-weight: 500">Cửa hàng yêu cầu chuyển đến:</span>
          {{ currentFormOpenModal?.targetStoreName }}
        </p>
      </div>
      <div class="d-flex justify-content-start">
        <b-button @click="hideModalReject" variant="primary" size="sm"
          >Quay lại</b-button
        >
        <b-button
          @click="onHandleReject"
          variant="warning"
          class="ml-3"
          size="sm"
          >Tiến hành từ chối</b-button
        >
      </div>
    </b-modal>
    <b-modal
      ref="modal-delete"
      id="modal-delete"
      title="Bạn có đồng ý xoá yêu cầu này không?"
      hide-footer
    >
      <div>
        <p>
          <span style="font-weight: 500">Họ tên nhân viên:</span>
          {{ currentFormOpenModal?.employeeName }}
          {{
            currentFormOpenModal?.employeeCode
              ? `(${currentFormOpenModal?.employeeCode})`
              : ''
          }}
        </p>
        <p>
          <span style="font-weight: 500">Cửa hàng hiện tại:</span>
          {{ currentFormOpenModal?.currentStoreName }}
        </p>
        <p>
          <span style="font-weight: 500">Cửa hàng yêu cầu chuyển đến:</span>
          {{ currentFormOpenModal?.targetStoreName }}
        </p>
      </div>
      <div class="d-flex justify-content-start">
        <b-button @click="hideModalDelete" variant="primary" size="sm"
          >Quay lại</b-button
        >
        <b-button
          @click="onHandleDelete"
          variant="danger"
          class="ml-3"
          size="sm"
          >Tiến hành xoá đơn</b-button
        >
      </div>
    </b-modal>
    <b-modal
      ref="modal-approve-many-items"
      id="modal-approve-many-items"
      title="Bạn có đồng ý duyệt đơn không?"
      hide-footer
    >
      <div>
        <p
          v-for="formItem of currentManyFormsOpenModal"
          :key="formItem.id"
          class="text-no-wrap flex justify-content-between"
        >
          <span class="text-info" style="flex: 30%"
            >{{ formItem?.employeeName }}
            {{
              formItem.employeeCode ? `(${formItem?.employeeCode})` : ''
            }}</span
          >
          <span class="text-success ml-2" style="flex: 35%">{{
            formItem.currentStoreName
          }}</span>
          <span class="text-danger ml-2" style="flex: 35%">-> {{
            formItem.targetStoreName
          }}</span>
        </p>
      </div>
      <div class="d-flex justify-content-start">
        <b-button @click="hideModalApproveManyItems" variant="primary" size="sm"
          >Quay lại</b-button
        >
        <b-button
          variant="success"
          class="ml-3"
          @click="onHandleApproveManyItems"
          size="sm"
          >Đồng ý</b-button
        >
      </div>
    </b-modal>
    <b-modal
      ref="modal-reject-many-items"
      id="modal-reject-many-items"
      title="Bạn có đồng ý từ chối yêu cầu không?"
      hide-footer
    >
      <div>
        <p
          v-for="formItem of currentManyFormsOpenModal"
          :key="formItem.id"
          class="text-no-wrap flex justify-content-between"
        >
          <span class="text-info" style="flex: 30%"
            >{{ formItem?.employeeName }}
            {{
              formItem?.employeeCode ? `(${formItem?.employeeCode})` : ''
            }}</span
          >
          <span class="text-success ml-2" style="flex: 35%">{{
            formItem.currentStoreName
          }}</span>
          <span class="text-danger ml-2" style="flex: 35%">-> {{
            formItem.targetStoreName
          }}</span>
        </p>
      </div>
      <div class="d-flex justify-content-start">
        <b-button @click="hideModalRejectManyItems" variant="primary" size="sm"
          >Quay lại</b-button
        >
        <b-button
          @click="onHandleRejectManyItems"
          variant="warning"
          class="ml-3"
          size="sm"
          >Tiến hành từ chối</b-button
        >
      </div>
    </b-modal>
    <b-modal
      ref="modal-delete-many-items"
      id="modal-delete-many-items"
      title="Bạn có đồng ý xoá yêu cầu không?"
      hide-footer
    >
      <div>
        <p
          v-for="formItem of currentManyFormsOpenModal"
          :key="formItem.id"
          class="text-no-wrap flex justify-content-between"
          :v-if="formItem"
        >
          <span class="text-info" style="flex: 30%"
            >{{ formItem.employeeName }}
            {{
              formItem.employeeCode ? `(${formItem?.employeeCode})` : ''
            }}</span
          >
          <span class="text-success ml-2" style="flex: 35%">{{
            formItem.currentStoreName
          }}</span>
          <span class="text-danger ml-2" style="flex: 35%">-> {{
            formItem.targetStoreName
          }}</span>
        </p>
      </div>
      <div class="d-flex justify-content-start">
        <b-button @click="hideModalDeleteManyItems" variant="primary" size="sm"
          >Quay lại</b-button
        >
        <b-button
          @click="onHandleDeleteManyItems"
          variant="danger"
          class="ml-3"
          size="sm"
          >Tiến hành Xoá đơn</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import {
convertPrice,
getEmployeeFromLocal,
makeToastFaile,
makeToastSuccess
} from '@/utils/common';
import { TRANSFER_STORE_STATUS } from '@/utils/constants';
import { JOB_TITLE, JOB_TITLE_APPROVE, TRANSFER_STORE_FORM_STATUS } from '@/utils/enum';
import AddTransferStoreFormModal from '@/view/components/AddTransferStoreFormModal.vue'
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import StatusForm from '@/view/components/transfer-store-forms/StatusForm.vue';
import KTCodePreview from '@/view/content/CodePreview.vue';
import Loader from '@/view/content/Loader.vue';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import moment from 'moment';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import datePicker from 'vue-bootstrap-datetimepicker';
import { mapGetters } from 'vuex';
import localData from '@/utils/saveDataToLocal';
import TransferStoreInformation from '../../pages/transfer-store-forms/TransferStoreInformation.vue';


export default {
  data() {
    return {
      tabIndex: 0,
      onLoading: false,
      search: '',
      searchCurrentStore: '',
      searchTargetStore: '',
      filteredOptions: [],
      selectedCurrentStore: undefined,
      selectedTargetStore: undefined,
      status: null,
      createdAtStart: undefined,
      createdAtEnd: undefined,
      page: 1,
      numberOfPages: 1,
      limitPerPage: 10,
      isSearching: false,
      currentPage: 1,
      totalItems: 0,
      count: 1,
      currentApprovedFormId: null,
      currentRejectedFormId: null,
      currentDeletedFormId: null,      
      selections: [],
      allowUpdateManyItems: false,
      checkAllItems: false,
      currentFormOpenModal: null,
      currentManyFormsOpenModal: [],
      employeeId: null,
      jobTitleId: null,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      statusEnum: TRANSFER_STORE_FORM_STATUS,
      permission: {
        viewList: 'TRANSFER_STORE_FORM_VIEW',
        viewDetail: 'VIEW_DETAIL_TRANSFER_STORE_FORM',
        create: 'CREATE_TRANSFER_STORE_FORM',
        edit: 'EDIT_TRANSFER_STORE_FORM',
      },
      options: [],
      fields: [
        {
          key: 'selections',
          label: '',
          thStyle: {
            width: '5%',
          },
          requiresPermission: true
        },
        {
          key: 'employeeCode',
          label: 'Mã Nhân viên',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '7%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'employeeName',
          label: 'Tên nhân viên',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '12%',
          },
        },
        {
          key: 'jobTitleName',
          label: 'Bộ phận - Chức vụ',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '12%',
          },
        },
        {
          key: 'currentStoreName',
          label: 'Cửa hàng hiện tại',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '13%',
          },
        },
        {
          key: 'targetStoreName',
          label: 'Cửa hàng chuyển đến',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '13%',
          },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '13%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo phiếu',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '13%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            width: '5%',
          },
        },
      ],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      listForms: [],
    };
  },
  created() {
    this.fetchStore();
    this.fetchTransferStoreForms();
    this.userInfo = getEmployeeFromLocal();
    this.statusOptions = TRANSFER_STORE_STATUS;
    const employeeInfo = JSON.parse(
      window.localStorage.getItem('employee_info')
    );

    this.employeeId = employeeInfo?.employeeId || null;
    this.jobTitleId = employeeInfo?.jobTitleId || null;
  },
  methods: {
    handleTabsStatus(status,indexTab){
      this.tabIndex = indexTab
      this.status =status
      this.fetchTransferStoreForms();

    },
    async onFilter() {
      this.isSearching = true;

      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-transfer-store-forms',
      });
      this.fetchTransferStoreForms();
    },
    raisePermissionDenied() {
      makeToastFaile('Bạn không có quyền truy cập vào module này.');
    },
    checkPermissionByEmployId(item){
      if(item.jobTitleId == JOB_TITLE.GD) return true
      const employee = getEmployeeFromLocal()
      return employee.id !== item.employeeId
    },
     checkPermission: function (condition) {
      return localData.checkPermission(condition) ;
    },
    fetchTransferStoreForms: async function () {
      this.isLoading = true;
      this.page = this.$route.query.page;

      if (!this.page) {
        this.page = 1;
      }

      if (!this.search) {
        this.search = '';
      }
      const DATE_FORMAT = 'DD/MM/YYYY';
      const DATE_DB_FORMAT = 'YYYY-MM-DD';

      let createdAtStart = this.createdAtStart
        ? moment(this.createdAtStart, DATE_FORMAT).format(DATE_DB_FORMAT)
        : null;
      let createdAtEnd = this.createdAtEnd
        ? moment(this.createdAtEnd, DATE_FORMAT).format(DATE_DB_FORMAT)
        : null;

      if (this.createdAtStart) {
        if (!this.createdAtEnd) {
          createdAtEnd = moment(new Date()).format(DATE_DB_FORMAT);
        }

        if (
          new Date(this.createdAtStart).getTime() >
          new Date(this.createdAtEnd).getTime()
        ) {
          this.makeToastFaile('Ngày tạo từ phải nhỏ hơn ngày tạo đến');
          this.createdAtEnd = undefined;
          return;
        }
      }

      const queryParams = {
        params: {
          searchName: this.search,
          page: this.page,
          status: this.status,
          pageSize: this.limitPerPage,
          createdAtStart: createdAtStart,
          createdAtEnd: createdAtEnd,
          currentStoreId: this.currentStoreId,
          targetStoreId: this.targetStoreId,
        },
      };

      ApiService.query('transfer-store-forms', queryParams)
        .then((response) => {
          const resData = response.data.data;
          this.listForms = resData.list_transfer_store_form;
          this.totalItems = resData.total_row;
          this.numberOfPages = resData.total_page;
        })
        .catch((error) => {
          makeToastFaile(error.response.data.message);
        })
        .finally(() => {
          this.isLoading = false;
          this.resetSelectManyItem();
        });
    },
    onInputChangeCurrentStore(text) {
      if (!text) {
        text = '';
      }
      this.searchCurrentStore = text;
      const filteredData = this.options
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [...filteredData];
    },
    onInputChangeTargetStore(text) {
      if (!text) {
        text = '';
      }
      this.searchTargetStore = text;
      const filteredData = this.options
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [...filteredData];
    },
    fetchStore: async function () {
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.options = stores.map((element) => {
          return {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
        });
        this.filteredOptions = [ ...this.options ];
        this.searchCurrentStore = '';
        this.searchTargetStore = '';
      });
    },
    onSelectedCurrentStore(option) {
      this.selectedCurrentStore = option.item.id;
      this.searchCurrentStore = option.item.name;
      this.currentStoreId = option.item.id;
    },
    onSelectedTargetStore(option) {
      this.selectedTargetStore = option.item.id;
      this.targetStoreId = option.item.id;
      this.searchTargetStore = option.item.name;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    getLinkDoc(item) {
      return `#/transfer-store-forms/update-form?id=${item.id}`;
    },
    handleKeydown() {
      this.startedTyping();

      this.stoppedTyping(this.onFilter);
    },
    reset() {
      this.search = '';
      this.page = 1;
      this.status = null;
      this.limitPerPage = 10;
      this.createdAtStart = undefined;
      this.createdAtEnd = undefined;
      this.currentStoreId = null;
      this.targetStoreId = null;
      this.searchCurrentStore = null;
      this.searchTargetStore = null;
      this.statusOptions = TRANSFER_STORE_STATUS;
      this.listForms = [];
      this.resetSelectManyItem();
      this.fetchTransferStoreForms();
    },
    resetSelectManyItem() {
      this.selections = [];
      this.allowUpdateManyItems = false;
      this.checkAllItems = false;
    },
    formatMoney(number) {
      return convertPrice(number);
    },
    editItem(item) {
      this.$router.push({
        path: '/transfer-store-forms/edit-form',
        query: { id: item.id },
      });
    },
    hideModalApproveTransfer(){
      this.$refts['modal-approve-transfer'].hide()
      this.currentApprovedFormId = null
    },
    hideModalApprove() {
      this.$refs['modal-approve'].hide();
      this.currentApprovedFormId = null;
    },
    hideModalReject() {
      this.$refs['modal-reject'].hide();
      this.currentRejectedFormId = null;
    },
    hideModalDelete() {
      this.$refs['modal-delete'].hide();
      this.currentDeletedFormId = null;
    },
    onHandleApproveTransfer(){
      const reqData = {
        status: TRANSFER_STORE_FORM_STATUS.APROVED_TRANSFER
      }
      ApiService.update(
        'transfer-store-forms',
        this.currentApprovedFormId,
        reqData
      )
        .then((res) => {
          makeToastSuccess(res.data.message);
          this.fetchTransferStoreForms();
        })
        .catch((err) => {
          console.log('err',err.response)
          makeToastFaile(err.response.data.message);
        })
        .finally(() => {
          this.currentApprovedFormId = null;
          this.$refs['modal-approve-transfer'].hide();
        });
    },
    onHandleApprove() {
      const reqData = {
        status: TRANSFER_STORE_FORM_STATUS.APPROVED,
      };
      ApiService.update(
        'transfer-store-forms',
        this.currentApprovedFormId,
        reqData
      )
        .then((res) => {
          makeToastSuccess(res.data.message);
          this.fetchTransferStoreForms();
        })
        .catch((err) => {
          makeToastFaile(err.response.data.message);
        })
        .finally(() => {
          this.currentApprovedFormId = null;
          this.$refs['modal-approve'].hide();
        });
    },
    onHandleReject() {
      const reqData = {
        status: TRANSFER_STORE_FORM_STATUS.REJECTED,
      };

      ApiService.update(
        'transfer-store-forms',
        this.currentRejectedFormId,
        reqData
      )
        .then((res) => {
          makeToastSuccess(res.data.message);
        })
        .catch((err) => {
          makeToastFaile(err.response.data.message);
        })
        .finally(() => {
          this.currentRejectedFormId = null;
          this.$refs['modal-reject'].hide();
          setTimeout(() => {
            this.fetchTransferStoreForms();
          }, 500);
        });
    },
    onHandleDelete() {
      ApiService.delete(`transfer-store-forms/${this.currentDeletedFormId}`)
        .then((res) => {
          makeToastSuccess(res.data.message);
        })
        .catch((err) => {
          makeToastFaile(err.response.data.message);
        })
        .finally(() => {
          this.currentRejectedFormId = null;
          this.$refs['modal-delete'].hide();
          setTimeout(() => {
            this.fetchTransferStoreForms();
          }, 500);
        });
    },
    async onHandleApproveTransferManyItems(){
     
      const reqData = {
        status: TRANSFER_STORE_FORM_STATUS.APROVED_TRANSFER,
      };

      const responsesFail = [];
      const responsesSuccess = [];

      for (const id of this.selections) {
        await ApiService.update('transfer-store-forms', id, reqData)
          .then(() => {
            let formItem = this.listForms.find((item) => item.id === id);
            responsesSuccess.push(formItem);
          })
          .catch((err) => {
            responsesFail.push(err.response.data.message);
          });
      }

      if (responsesFail.length) {
        let msgResponse = `Cập nhật không thành công ${responsesFail.length} phiếu của:`;
        for (let [i, response] of responsesFail.entries()) {
          if (i !== 0) {
            msgResponse += ', ';
          }
          msgResponse += `${response}`;
        }

        makeToastFaile(msgResponse);
      }
      this.$refs['modal-approve-transfer-many-items'].hide();
      if (responsesSuccess.length) {
        if (responsesSuccess.length === this.selections.length) {
          makeToastSuccess('Cập nhật thành công.');
        } else {
          let msgResponse = `Cập nhật thành công ${responsesSuccess.length} phiếu của:`;
          for (const [i, response] of responsesSuccess.entries()) {
            if (i !== 0) {
              msgResponse += ', ';
            }
            msgResponse += `${response.employeeName} ${response.employeeCode}`;
          }
          makeToastSuccess(msgResponse);
        }
      }

      setTimeout(() => {
        this.fetchTransferStoreForms();
      }, 500);
    },

    async onHandleApproveManyItems() {
      const reqData = {
        status: TRANSFER_STORE_FORM_STATUS.APPROVED,
      };

      const responsesFail = [];
      const responsesSuccess = [];

      for (const id of this.selections) {
        await ApiService.update('transfer-store-forms', id, reqData)
          .then(() => {
            let formItem = this.listForms.find((item) => item.id === id);
            responsesSuccess.push(formItem);
          })
          .catch((err) => {
            responsesFail.push(err.response.data.message);
          });
      }

      if (responsesFail.length) {
        let msgResponse = `Cập nhật không thành công ${responsesFail.length} phiếu của:`;
        for (let [i, response] of responsesFail.entries()) {
          if (i !== 0) {
            msgResponse += ', ';
          }
          msgResponse += `${response}`;
        }

        makeToastFaile(msgResponse);
      }
      this.$refs['modal-approve-many-items'].hide();
      if (responsesSuccess.length) {
        if (responsesSuccess.length === this.selections.length) {
          makeToastSuccess('Cập nhật thành công.');
        } else {
          let msgResponse = `Cập nhật thành công ${responsesSuccess.length} phiếu của:`;
          for (const [i, response] of responsesSuccess.entries()) {
            if (i !== 0) {
              msgResponse += ', ';
            }
            msgResponse += `${response.employeeName} ${response.employeeCode}`;
          }
          makeToastSuccess(msgResponse);
        }
      }

      setTimeout(() => {
        this.fetchTransferStoreForms();
      }, 500);
    },

    async onHandleRejectManyItems() {
      const reqData = {
        status: TRANSFER_STORE_FORM_STATUS.REJECTED,
      };

      let responsesFail = [];
      let responsesSuccess = [];

      for (const id of this.selections) {
        await ApiService.update('transfer-store-forms', id, reqData)
          .then(() => {
            const formItem = this.listForms.find((item) => item.id === id);
            responsesSuccess.push(formItem);
          })
          .catch((err) => {
            responsesFail.push(err.response.data.message);
          });
      }

      this.$refs['modal-reject-many-items'].hide();
      if (responsesFail.length) {
        let msgResponse = `Cập nhật không thành công ${responsesFail.length} phiếu của:`;
        for (const [i, response] of responsesFail.entries()) {
          if (i !== 0) {
            msgResponse += ', ';
          }
          msgResponse += `${response}`;
        }

        makeToastFaile(msgResponse);
      }

      if (responsesSuccess.length) {
        if (responsesSuccess.length === this.selections.length) {
          makeToastSuccess('Cập nhật thành công.');
        } else {
          let msgResponse = `Cập nhật thành công ${responsesSuccess.length} phiếu của:`;
          for (const [i, response] of responsesSuccess.entries()) {
            if (i !== 0) {
              msgResponse += ', ';
            }
            msgResponse += `${response.employeeName} ${response.employeeCode}`;
          }
          makeToastSuccess(msgResponse);
        }
      }

      setTimeout(() => {
        this.fetchTransferStoreForms();
      }, 500);
    },
    async onHandleDeleteManyItems() {
      let responsesFail = [];
      let responsesSuccess = [];

      for (const id of this.selections) {
        await ApiService.delete(`transfer-store-forms/${id}`)
          .then(() => {
            let formItem = this.listForms.find((item) => item.id === id);
            responsesSuccess.push(formItem);
          })
          .catch((err) => {
            responsesFail.push(err.response.data.message);
          });
      }

      this.$refs['modal-delete-many-items'].hide();
      if (responsesFail.length) {
        let msgResponse = `Cập nhật không thành công ${responsesFail.length} phiếu của:`;
        for (const [i, response] of responsesFail.entries()) {
          if (i !== 0) {
            msgResponse += ', ';
          }
          msgResponse += `${response}`;
        }

        makeToastFaile(msgResponse);
      }

      if (responsesSuccess.length) {
        if (responsesSuccess.length === this.selections.length) {
          makeToastSuccess('Cập nhật thành công.');
        } else {
          let msgResponse = `Cập nhật thành công ${responsesSuccess.length} phiếu của:`;
          for (const [i, response] of responsesSuccess.entries()) {
            if (i !== 0) {
              msgResponse += ', ';
            }
            msgResponse += `${response.employeeName} ${response.employeeCode}`;
          }
          makeToastSuccess(msgResponse);
        }
      }

      setTimeout(() => {
        this.fetchTransferStoreForms();
      }, 500);
    },
    onSelectFormItem(id) {
      if (this.selections.includes(id)) {
        this.selections = this.selections.filter((_id) => _id !== id);
      } else {
        this.selections = [...this.selections, id];
      }

      this.checkAllowUpdateManyItems();
    },
    checkAllowUpdateManyItems() {
      if (!this.selections.length) return (this.allowUpdateManyItems = false);
        if(!this.checkPermission('EDIT_TRANSFER_STORE_FORM')){
          this.allowUpdateManyItems =false
          return false
        }

      let isAllowed = true;
      let formsOpenModal = [];
      for (const selectedItem of this.selections) {
        let formSelectedItem = this.listForms.find(
          (item) => item.id === selectedItem
        );
        if (
          !formSelectedItem ||
          formSelectedItem.status !== 1 ||
          (formSelectedItem.createdBy === this.employeeId &&
            this.jobTitleId !== 1)
        ) {
          isAllowed = false;
          break;
        }

        let formItem = this.listForms.find((item) => item.id === selectedItem);
        if (formItem) {
          formsOpenModal.push(formItem);
        }
      }

      if (!isAllowed) {
        this.currentManyFormsOpenModal = [];
      } else {
        this.currentManyFormsOpenModal = formsOpenModal;
      }

      this.allowUpdateManyItems = isAllowed;
      return isAllowed;
    },
    onCheckAllItems() {
      if (!this.checkAllItems) {
        this.resetSelectManyItem();
        return;
      }
      this.selections = this.listForms.map((item) => item.id);
      this.checkAllowUpdateManyItems();
    },
    hideModalApproveTransferManyItems(){
      this.$refs['modal-approve-transfer-many-items'].hide()
    },
    hideModalApproveManyItems() {
      this.$refs['modal-approve-many-items'].hide();
    },
    hideModalRejectManyItems() {
      this.$refs['modal-reject-many-items'].hide();
    },
    hideModalDeleteManyItems() {
      this.$refs['modal-delete-many-items'].hide();
    },
    onOpenModalApprove(item) {
      this.currentApprovedFormId = item.id;
      this.currentFormOpenModal = item;
    },
    onOpenModalApproveTransfer(item){
      this.currentManyFormsOpenModal = [item]
      this.currentApprovedFormId = item.id;
      this.currentFormOpenModal = item;
    },
    onOpenModalReject(item) {
      this.currentRejectedFormId = item.id;
      this.currentFormOpenModal = item;
    },
    onOpenModalDelete(item) {
      this.currentDeletedFormId = item.id;
      this.currentFormOpenModal = item;
    },
    checkPermissionAllowUpdate(targetStoreId) {
      const { listStoreId, jobTitleId } = this.userInfo;
      const listStoreIdArray = listStoreId.split(', ');

      if (
        jobTitleId === JOB_TITLE.GD ||
        (JOB_TITLE_APPROVE.includes(jobTitleId) &&
          listStoreIdArray.includes(targetStoreId.toString()))
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu yêu cầu chuyển cửa hàng', route: 'transfer-store-forms' },
      { title: 'Danh sách phiếu yêu cầu chuyển cửa hàng' },
    ]);
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    computedFields() {
      if( this.status != 1 )
        return this.fields.filter(field => !field.requiresPermission);
      else
        return this.fields;
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
    storeName(){
      const { storeId } = this.userInfo; 
      const store = this.filteredOptions.find(item => item.id === storeId );
      return store.name;
    }
  },
  watch: {
    selections(newSelections) {
      if (newSelections?.length === this.listForms.length) {
        this.checkAllItems = true;
        return;
      }
      this.checkAllItems = false;
    },
    fetchTransferStoreForms(){
      this.fetchTransferStoreForms();
    }
  },
  components: { Loader, KTCodePreview, StatusForm, datePicker, Autosuggest, AddTransferStoreFormModal,TransferStoreInformation },
};
</script>

<style scoped>

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

.productCode:hover {
  text-decoration: underline;
}
</style>
<style>
.tab-status{
  background-color: ghostwhite;
}
.tab-status .nav .nav-link {
  font-weight: bold ; 
  font-size: 1.1rem;
  color: black;
}
.tab-status .nav .nav-link:hover{
  color: slateblue;
}
.tab-status .nav .nav-link.active{
  color: slateblue;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
label.custom-datetime-picker {
  width: 100%;
}

.bootstrap-datetimepicker-widget {
  width: 92% !important;
}
</style>
